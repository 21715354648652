import React from "react";
import PropTypes from "prop-types";

import LEVELS from "def/levels";
import {
  toast as importedToast,
  ToastContainer as ImportedToastContainer,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Icon from "components/Icon";
import StatusIcon from "./status-icon";

export const NOTIFICATION_TYPE = {
  PRIMARY: "PRIMARY",
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
};

const NOTIFICATION_PALETTE = {
  PRIMARY: {
    background: "bg-purple-500",
    text: "text-white",
  },
  SUCCESS: {
    background: "bg-green-400",
    text: "text-white",
  },
  WARNING: {
    background: "bg-yellow-300",
    text: "text-gray-500",
  },
  ERROR: {
    background: "bg-red-500",
    text: "text-white",
  },
};

export const toast = importedToast;

export const ToastContainer = ({ position, delay }) => {
  return (
    <ImportedToastContainer
      position={position}
      closeButton={false}
      autoClose={delay}
      hideProgressBar
      newestOnTop
      closeOnClick={false}
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
    />
  );
};

export function ToastContent({
  toastType,
  notificationType,
  icon,
  title,
  message,
  children,
  closeToast,
  actions,
}) {
  return (
    <div
      className={`${
        notificationType
          ? NOTIFICATION_PALETTE[notificationType].text
          : "text-gray-500"
      } inset-0 flex items-end justify-center pointer-events-none sm:items-start sm:justify-end`}
    >
      <div
        className={`${
          notificationType
            ? NOTIFICATION_PALETTE[notificationType].background
            : "bg-white"
        } max-w-md w-full shadow-lg rounded-lg pointer-events-auto`}
      >
        <div className="flex rounded-lg shadow-xs">
          <div className="w-0 flex-1 flex p-4">
            {toastType ? (
              <StatusIcon className="mr-2" level={toastType} />
            ) : (
              icon && (
                <div className="flex items-start">
                  <div className="mr-2 flex-shrink-0">
                    <Icon icon={icon} />
                  </div>
                </div>
              )
            )}
            <div className="w-full">
              <p className="text-sm leading-5 font-bold">{title && title}</p>
              <p className="text-sm leading-5">{message || children}</p>
            </div>
          </div>
          {actions === undefined ? (
            <div
              className="p-4 flex-shrink-0 flex items-start"
              onClick={() => closeToast()}
            >
              <button className="inline-flex focus:outline-none transition ease-in-out duration-150">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          ) : (
            <div className="flex border-l border-gray-200">
              <div className="-ml-px flex flex-col">
                {actions?.callBack && (
                  <div
                    className={`${
                      actions.callBack ? "border-b" : ""
                    } h-0 flex-1 flex border-gray-200`}
                  >
                    <button
                      className="-mb-px flex items-center justify-center w-full rounded-tr-lg border border-transparent px-3 py-2 text-sm leading-5 font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-purple-700 active:bg-gray-50 transition ease-in-out duration-150"
                      onClick={() => actions.callBack()}
                    >
                      {actions.actionLabel}
                    </button>
                  </div>
                )}
                {actions.dismissCallBack && (
                  <div className="-mt-px h-0 flex-1 flex">
                    <button
                      className="flex items-center justify-center w-full rounded-br-lg border border-transparent px-3 py-2 text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-900 active:bg-gray-50 transition ease-in-out duration-150"
                      onClick={() => {
                        closeToast();
                        actions.dismissCallBack();
                      }}
                    >
                      Dismiss
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ToastContainer.propTypes = {
  delay: PropTypes.number,
  level: PropTypes.oneOf(Object.values(LEVELS)),
};

ToastContainer.defaultProps = {
  delay: 4000,
  position: "top-right",
};
