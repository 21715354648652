import createApolloClient from "@farpoint/common/apollo-client";
import { getUser } from "@farpoint/common/user-service";

const client = createApolloClient(
  getUser,
  process.env.NODE_ENV === "development"
    ? "http://localhost:9000/graphql"
    : `https://${window.location.host}/${process.env.REACT_APP_GRAPHQL_URI}`
);

export default client;
