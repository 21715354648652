import React from "react";
import "styled-components/macro";
import Button from "components/Button";

function getIcon(success, error, warning) {
  if (success) {
    return (
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
        <svg
          className="h-6 w-6 text-green-600"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
    );
  }
  if (error) {
    return (
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
        <svg
          className="h-6 w-6 text-red-600"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
      </div>
    );
  }
  if (warning) {
    return (
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
        <svg
          className="h-6 w-6 text-yellow-600"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
      </div>
    );
  }
  return (
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
      <svg
        className="h-6 w-6 text-blue-600"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
      >
        <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
      </svg>
    </div>
  );
}

function FullpageNotification(props) {
  const { title, info, cta, success, error, warning } = props;

  return (
    <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
      <div className="sm:max-w-sm sm:w-full sm:p-6">
        <div>
          {getIcon(success, error, warning)}
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
            {info && (
              <div className="mt-2">
                <p className="text-center text-sm leading-5 text-gray-700">
                  {info}
                </p>
              </div>
            )}
          </div>
        </div>
        {cta && (
          <div className="mt-5 sm:mt-6">
            <span className="flex justify-center rounded-md">
              <Button primary onClick={cta.onClick}>
                {cta.label}
              </Button>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default FullpageNotification;
