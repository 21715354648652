import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import gql from "graphql-tag";

import { useQuery } from "@apollo/client";

const Product = React.lazy(() => import("pages/product"));
const ProductListing = React.lazy(() => import("pages/product-listing"));

const AddProduct = React.lazy(() => import("pages/add-product"));
const AddProductCertificates = React.lazy(() =>
  import("pages/add-product-certificates")
);
const AddProductVideos = React.lazy(() => import("pages/add-product-videos"));
const AddProductBuyable = React.lazy(() => import("pages/add-product-buyable"));
const EditProductBuyable = React.lazy(() =>
  import("pages/edit-product-buyable")
);

const GET_PRODUCT_FAMILIES = gql`
  query productFamilies {
    productFamilies {
      id
      formData
      name
      slug
    }
  }
`;

function ProductRoutes() {
  const { data: productFamilies } = useQuery(GET_PRODUCT_FAMILIES);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/products/all" />} />
        <Route path="/all" element={<ProductListing />} />

        {productFamilies?.productFamilies?.map((productFamily) => (
          <Route
            path={`/:${productFamily.slug}`}
            key={productFamily.id}
            element={<ProductListing family={productFamily} />}
          />
        ))}

        <Route path="/new" element={<AddProduct />} />
        <Route path="/:id" exact element={<Product />} />
        <Route
          path="/:id/add-certificates"
          element={<AddProductCertificates />}
        />
        <Route path="/:id/add-videos" element={<AddProductVideos />} />
        <Route path="/:id/add-buyable" element={<AddProductBuyable />} />
        <Route path="/:id/edit-buyable" element={<EditProductBuyable />} />
      </Routes>
    </>
  );
}

export default ProductRoutes;
