import React from "react";
import ReactDOM from "react-dom";

import "icons/css/style.css";
import "./styles/index.css";

import Aldi from "@farpoint/aldi";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { getUser } from "@farpoint/common/user-service";

process.env.NODE_ENV !== "production" &&
  new Aldi({
    target: document.querySelector("#aldi-container"),
    props: {
      user: getUser(),
    },
  });

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
