import React from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
import { isLogged } from "@farpoint/common/user-service";

export default ({ element, path, ...props }) => {
  const location = useLocation();

  return (
    <Route
      {...props}
      element={
        isLogged() ? (
          element
        ) : (
          <Navigate
            to={`/sign-in?redirect=${location.pathname + location.search}`}
          />
        )
      }
    />
  );
};
