import React, { createContext, useCallback, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const queryStringContext = createContext();
const { Provider } = queryStringContext;

export function useQueryString() {
  const { qs, actions } = React.useContext(queryStringContext);
  return [qs, actions];
}

function QueryStringProvider(props) {
  const { children } = props;
  const { search, pathname } = useLocation();

  const [urlSearchParams, setSearchParams] = useState(
    new URLSearchParams(search)
  );

  const getObjFromSearchParams = useCallback(
    function getObjFromSearchParams() {
      return queryString.parse(urlSearchParams.toString());
    },
    [urlSearchParams]
  );

  const setQueryString = useCallback(
    function setQueryString(params = {}) {
      setSearchParams(new URLSearchParams(queryString.stringify(params)));
    },
    [setSearchParams]
  );

  const resetQueryString = useCallback(
    function resetQueryString() {
      setSearchParams(new URLSearchParams([]));
    },
    [setSearchParams]
  );

  const addToQueryString = useCallback(
    function addToQueryString(param) {
      Object.entries(param).forEach(([key, value]) =>
        urlSearchParams.append(key, value)
      );
    },
    [urlSearchParams]
  );

  const removeFromQueryString = useCallback(
    function removeFromQueryString(name, value) {
      const precValues = urlSearchParams.getAll(name);
      const newValues = precValues.filter((val) => val !== value);

      const newQuerystring = getObjFromSearchParams();
      delete newQuerystring[name];

      if (newValues.length > 0) {
        newQuerystring[name] = newValues;
      }

      setQueryString(newQuerystring);
    },
    [urlSearchParams, setQueryString, getObjFromSearchParams]
  );

  useEffect(
    () =>
      void window.history.replaceState(
        {},
        "",
        `${pathname}?${urlSearchParams}`
      ),
    [pathname, urlSearchParams]
  );

  return (
    <Provider
      value={{
        qs: urlSearchParams,
        actions: {
          setQueryString,
          resetQueryString,
          addToQueryString,
          removeFromQueryString,
          getObjFromSearchParams,
        },
      }}
    >
      {children}
    </Provider>
  );
}

export default QueryStringProvider;
