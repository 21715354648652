import React from "react";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import "styled-components/macro";

import { getUser } from "@farpoint/common/user-service";

import logo from "images/logo.svg";

import NavItem from "components/nav-item";

const GET_NUMBERS = gql`
  query getNumbers {
    buyOrders {
      id
    }
    sellOrders {
      id
    }
    unreadNotifications {
      id
    }
    myProducts {
      id
    }
  }
`;

function MMLayout(props) {
  const { header, children, unspaced } = props;
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const user = getUser();

  const { data } = useQuery(GET_NUMBERS);

  const numbers = React.useMemo(() => {
    return {
      notifications: data?.unreadNotifications.length,
      buyOrders: data?.buyOrders.length,
      sellOrders: data?.sellOrders.length,
      myProducts: data?.myProducts.length,
    };
  }, [data]);

  const navigationItems = React.useMemo(() => {
    return [
      {
        label: "Browse",
        type: "section",
        children: [
          {
            label: "Products",
            to: "/products",
            icon: "grid-interface",
          },
          {
            label: "Your favorites",
            to: "/favorites",
            icon: "shape-star",
          },
        ],
      },

      {
        label: "Dashboard",
        type: "section",
        children: [
          {
            label: "Your products",
            to: "/dashboard/my-products",
            icon: "grid-interface",
          },
          {
            label: "Buy Orders",
            to: "/dashboard/buy-orders",
            tag: numbers.buyOrders,
            icon: "buy-order",
          },
          {
            label: "Sell Orders",
            to: "/dashboard/sell-orders",
            tag: numbers.sellOrders,
            icon: "sell-order",
          },
          {
            label: "Notifications",
            to: "/dashboard/notifications",
            tag: numbers.notifications,
            icon: "email",
          },
        ],
      },
    ];
  }, [numbers]);

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      {/* Off-canvas menu for mobile */}
      <div className="md:hidden">
        <div className={`${!isMenuOpen && "hidden"} fixed inset-0 flex z-40`}>
          {/*
            Off-canvas menu overlay, show/hide based on off-canvas menu state.

            Entering: "transition-opacity ease-linear duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "transition-opacity ease-linear duration-300"
              From: "opacity-100"
              To: "opacity-0"
          */}
          <div className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-600 opacity-75" />
          </div>
          {/*
            Off-canvas menu, show/hide based on off-canvas menu state.

            Entering: "transition ease-in-out duration-300 transform"
              From: "-translate-x-full"
              To: "translate-x-0"
            Leaving: "transition ease-in-out duration-300 transform"
              From: "translate-x-0"
              To: "-translate-x-full"
          */}
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
            <div className="absolute top-0 right-0 -mr-14 p-1">
              <button
                className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                aria-label="Close sidebar"
                onClick={() => setMenuOpen(!isMenuOpen)}
              >
                <svg
                  className="h-6 w-6 text-white"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-8 w-auto" src={logo} alt="Farpoint.io" />
              </div>
              <nav className="mt-8 px-2 capitalize">
                {navigationItems.map((item, index) => (
                  <NavItem key={`${item.to}_${index}`} {...item} />
                ))}
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <span className="flex-shrink-0 group block focus:outline-none">
                <div className="flex items-center">
                  <div className="ml-3">
                    <p className="text-base leading-6 font-medium text-gray-700 group-hover:text-gray-900">
                      {user.fullName}
                    </p>
                    <Link
                      to="/logout"
                      className="text-sm leading-5 font-medium text-gray-500 group-hover:text-gray-700 group-focus:underline transition ease-in-out duration-150"
                    >
                      Sign Out
                    </Link>
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </div>
      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div
          className="flex flex-col w-80 border-gray-200 border-r bg-white"
          css={`
            background-color: #fafafa;
          `}
        >
          <div className="h-0 flex-1 flex flex-col pt-5 pb-4 ">
            <div className="flex items-center flex-shrink-0 px-4">
              <img className="h-8 w-auto ml-6" src={logo} alt="Farpoint.io" />
            </div>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <nav className="mt-20 px-4 flex-1 capitalize">
              {navigationItems.map((item, index) => (
                <NavItem key={`${item.to}_${index}`} {...item} />
              ))}
            </nav>
          </div>
          <div className="flex-shrink-0 flex p-4">
            <span className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div className="ml-6">
                  <p className="text-sm leading-5 font-medium text-gray-700 group-hover:text-gray-900">
                    {user.fullName}
                  </p>
                  <Link
                    to="/logout"
                    className="text-xs leading-4 font-medium text-gray-500 group-hover:text-gray-700 transition ease-in-out duration-150"
                  >
                    Sign Out
                  </Link>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden flex justify-between p-1 sm:p-3">
          <div className="flex-shrink-0 flex items-center pl-2">
            <img className="h-8 w-auto" src={logo} alt="Farpoint.io" />
          </div>
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
            aria-label="Open sidebar"
            onClick={() => setMenuOpen(!isMenuOpen)}
          >
            <svg
              className="h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        {unspaced ? (
          <main className="flex-1 sm:pl-8 overflow-y-auto sm:pl-10 md:pl-12">
            {children}
          </main>
        ) : (
          <main
            className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
            tabIndex={0}
          >
            <div className="px-4 sm:px-10 md:px-12 max-w-6xl">{header}</div>
            <div className="px-4 sm:px-10 md:px-12 max-w-6xl">
              {/* Replace with your content */}
              <div className="py-4">{children}</div>
              {/* /End replace */}
            </div>
          </main>
        )}
      </div>
    </div>
  );
}

export default MMLayout;
