import React from "react";
import PropTypes from "prop-types";

import Icon from "components/Icon";
import LEVELS, { COLORS, ICONS } from "def/levels";

function StatusIcon({ level, size, className }) {
  let localClassName = `
    flex
    center
    items-center
    justify-center
    ${COLORS[level][0]}
    ${COLORS[level][1]} 
    rounded-full 
  `;

  if (size === 0) {
    localClassName += `
      w-3
      h-3
    `;
  } else {
    localClassName += `
      w-7
      h-7
    `;
  }

  return (
    <div
      className={`
       ${localClassName}
        ${className}
      `}
    >
      {size === 0 ? "" : <Icon icon={ICONS[level]} unspaced />}
    </div>
  );
}

StatusIcon.defaultProps = {
  level: 0,
  className: "",
};

StatusIcon.propTypes = {
  level: PropTypes.oneOf(Object.values(LEVELS)),
};

export default StatusIcon;
