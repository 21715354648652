import React from "react";
import { useState, useRef, useContext, useLayoutEffect } from "react";
import "styled-components/macro";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import Modal from "components/modal";
import { useMutation } from "@apollo/client";
import ErrorMessage from "components/error-message";

const CHECK_PIN = gql`
  mutation checkPIN($PIN: String!) {
    checkPIN(PIN: $PIN)
  }
`;

function PinModal(props) {
  const { onConfirm, onCancel, status } = props;

  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const numberOfDigits = 6;

  const pinFields = useRef([]);
  const checkFields = useRef([]);

  const [PIN, setPIN] = useState(false);

  const checks = [
    "I have read and agree to the Terms and Conditions",
    // "I understand that dolor sit amet consecutor"
  ];

  const validate = React.useCallback(() => {
    const value = pinFields.current.reduce(
      (pin, field) => (pin += field.value),
      ""
    );
    const regex = new RegExp(`^[0-9]{${numberOfDigits}}$`);

    const PINIsValid = regex.exec(value);

    const allChecked = checkFields.current.reduce(
      (valid, field) => field.checked && valid,
      true
    );

    if (PINIsValid && allChecked) setDisabled(false);
    else setDisabled(true);

    setPIN(value);
  }, [setDisabled]);

  const setCheckRef = React.useCallback(
    (i, el) => {
      checkFields.current[i] = el;
    },
    [checkFields]
  );

  const handleCheckChange = React.useCallback(
    function handleCheckChange(e) {
      validate();
    },
    [validate]
  );

  const lastActionIsBackspace = React.useRef(null);

  useLayoutEffect(() => {
    pinFields.current[0].focus();
  }, []);

  const setRef = React.useCallback(
    (i, el) => {
      pinFields.current[i] = el;
    },
    [pinFields]
  );

  const handleKey = React.useCallback(
    (index, e) => {
      const code = e.which || e.keyCode;

      // back key
      if (code === 8) {
        if (e.target.value === "" && index !== 0) {
          pinFields.current[index - 1].focus();
        } else {
          lastActionIsBackspace.current = true;
        }
      } else {
        lastActionIsBackspace.current = false;
      }

      // v
      if (
        code === 86 &&
        (e.getModifierState("Control") || e.getModifierState("Meta"))
      ) {
        console.log("do nothing?");

        // non digits
      } else if (code > 31 && (code < 48 || code > 57)) {
        if (code === 37) {
          const prev = index - 1;
          prev >= 0 && pinFields.current[index - 1].focus();
        }

        if (code === 39) {
          const next = index + 1;
          next < pinFields.current.length &&
            pinFields.current[index + 1].focus();
        }

        e.preventDefault();
        return;
      }
    },
    [pinFields]
  );

  const handleFocus = React.useCallback(
    (e) => {
      e.target.select();
      lastActionIsBackspace.current = false;
    },
    [lastActionIsBackspace]
  );

  // --- CHANGE
  const handleChange = React.useCallback(
    (index, e) => {
      if (lastActionIsBackspace.current && index !== 0) {
        pinFields.current[index - 1].focus();
      }

      if (index < pinFields.current.length - 1 && e.target.value !== "") {
        pinFields.current[index + 1].focus();
      }

      validate();
    },
    [pinFields, lastActionIsBackspace, validate]
  );

  const [checkPIN] = useMutation(CHECK_PIN);

  // --- PASTE
  const handlePaste = React.useCallback(
    (e) => {
      e.clipboardData
        .getData("text")
        .split("")
        .slice(0, numberOfDigits)
        .map((digit) => parseInt(digit, 10))
        .map((digit) => (!isNaN(digit) ? digit : ""))
        .forEach((digit, i) => {
          pinFields.current[i].value = digit;
        });

      pinFields.current[0].focus();
      validate();
    },
    [pinFields, validate, numberOfDigits]
  );

  // on confirm, we check if the pin is valid before using it to buy
  const handleConfirm = React.useCallback(async () => {
    try {
      const { data } = await checkPIN({ variables: { PIN } });

      if (data.checkPIN) {
        onConfirm(PIN);
        setError(false);
      } else {
        throw new Error("Invalid PIN.");
      }
    } catch (err) {
      setError(err.message);
    }
  }, [PIN, checkPIN, setError, onConfirm]);

  return (
    <Modal
      title={"PIN Required"}
      subtitle={
        status === 0 && (
          <div
            className="p-4 shadow rounded-lg bg-gray-100 text-left text-xs leading-tight"
            css={`
              > * + * {
                margin: 0.25rem 0;
              }
            `}
          >
            <div className="font-bold">WARNING!</div>
            <div>
              you are about to place a strictly BINDING order, that you will be
              OBLIGED to pay within 24 hours.
            </div>
            <div>Once confirmed, you will receive the bank details to pay.</div>
            <div>
              If this does not happen, your profile will be reported and you
              will be banned from this platform.
            </div>
            <div>
              If after the order you have any unforeseen events that will not
              allow you to pay promptly, please report it immediately to{" "}
              <a
                href="mailto:staff@farpoint.io"
                className="font-bold hover:text-purple-500"
              >
                staff@farpoint.io
              </a>
            </div>
          </div>
        )
      }
      confirmText={"Place Order"}
      confirmDisabled={disabled}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      status={"SUCCESS"}
    >
      <div data-testid={"pin-modal"}>
        <div className="flex justify-center py-8">
          {[...new Array(numberOfDigits)].map((_, i) => (
            <div key={i} className="flex items-center">
              <input
                ref={(el) => setRef(i, el)}
                onKeyDown={(e) => handleKey(i, e)}
                onChange={(e) => handleChange(i, e)}
                onFocus={handleFocus}
                onPaste={(e) => e.preventDefault()}
                maxLength={1}
                data-testid={`pin-input-${i}`}
                className="font-600 text-center form-input px-0 py-3 block w-8 h-10 transition ease-in-out duration-150"
                onPasteCapture={handlePaste}
              />

              {i < numberOfDigits - 1 && (
                <span className="text-sm px-2 items-center text-gray-700">
                  -
                </span>
              )}
            </div>
          ))}
        </div>

        <div className="mb-8">
          {checks.map((check, index) => (
            <label
              key={check}
              className="flex justify-center items-center mb-1"
            >
              <input
                onChange={handleCheckChange}
                ref={(el) => setCheckRef(index, el)}
                name={`check-${index}`}
                type="checkbox"
                className="form-checkbox h-4 w-4 text-purple-600 transition duration-150 ease-in-out"
              />
              <span className="ml-2 block text-sm leading-5 text-gray-900">
                {check}
              </span>
            </label>
          ))}
        </div>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    </Modal>
  );
}

PinModal.defaultProps = {
  title: "Hello World!",
};

PinModal.propsType = {
  title: PropTypes.string,
};

export default PinModal;

const PINContext = React.createContext();

export function usePINModal() {
  const { openModal } = useContext(PINContext);

  return [openModal];
}

export function PINProvider(props) {
  const [showModal, setModal] = React.useState();
  const [status, setStatus] = React.useState(-1);

  const promise = React.useRef();

  const _resolve = React.useRef();
  const _reject = React.useRef();

  const confirm = React.useCallback(
    (PIN) => {
      _resolve.current(PIN);
      setModal(false);
      setStatus(-1);
    },
    [setModal, _resolve]
  );

  const cancel = React.useCallback(() => {
    _reject.current();
    setModal(false);
    setStatus(-1);
  }, [setModal, _reject]);

  const openModal = React.useCallback(
    ({ orderStatus } = {}) => {
      if (typeof orderStatus === "number" && orderStatus > -1) {
        setStatus(orderStatus);
      }

      setModal(true);
      promise.current = new Promise((resolve, reject) => {
        _resolve.current = (x) => resolve(x);
        _reject.current = (y) => reject(y);
      });

      return promise.current;
    },
    [setModal, _resolve, _reject]
  );

  return (
    <PINContext.Provider value={{ openModal }}>
      {props.children}
      {showModal && (
        <PinModal onConfirm={confirm} onCancel={cancel} status={status} />
      )}
    </PINContext.Provider>
  );
}
