const Cookies = require("js-cookie");

export function persistUserData(data) {
  persistUser({ ...getUser(), ...data });
}

export function getUser() {
  const storedUser =
    localStorage.getItem("farpoint-user") ||
    Cookies.get("farpoint-user") ||
    "{}";

  const user = JSON.parse(storedUser);

  return user;
}

export function isLogged() {
  const { token } = getUser();

  return token;
}

export function persistUser(user) {
  const string = JSON.stringify(user);

  localStorage.setItem("farpoint-user", string);
  Cookies.set("farpoint-user", string, {
    expires: 1,
    domain: process.env.REACT_APP_DOMAIN,
  });
}

export function setUser(merge) {
  const user = getUser();
  const newUser = { ...user, ...merge };

  persistUser(newUser);
}

export function logout() {
  localStorage.removeItem("farpoint-user");
  Cookies.remove("farpoint-user");
}
