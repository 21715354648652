import React from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import "react-toastify/dist/ReactToastify.css";

import { ToastContent, ToastContainer, toast } from "components/toast";

const notificationsContext = React.createContext();

const GET_NOTIFICATIONS = gql`
  query getUnreadNotifications {
    unreadNotifications {
      id
      created
      message
    }
  }
`;

export function useNotifications() {
  const values = React.useContext(notificationsContext);

  return values;
}

function NotificationProvider(props) {
  const { data, startPolling } = useQuery(GET_NOTIFICATIONS);

  const unreadNotifications = data?.unreadNotifications;

  const notifications = React.useRef([]);
  const newNotifications = React.useRef([]);
  const lastCheck = React.useRef(new Date());

  const memoizedValue = React.useMemo(() => {
    return {
      hasNotifications: unreadNotifications?.length,
    };
  }, [unreadNotifications]);

  React.useEffect(() => {
    if (typeof unreadNotifications === "undefined") {
      return;
    }

    newNotifications.current = [];

    unreadNotifications.slice(0, 10).forEach((notification) => {
      if (new Date(notification.created) > lastCheck.current) {
        newNotifications.current.push(notification);
      }
    });

    lastCheck.current = new Date();

    notifications.current = notifications.current.concat(
      newNotifications.current
    );

    newNotifications.current.forEach((notification) =>
      toast(<ToastContent>{notification.message}</ToastContent>)
    );
  }, [unreadNotifications]);

  React.useEffect(() => {
    process.env.NODE_ENV === "production" && startPolling(5000);
  }, [startPolling]);

  return (
    <>
      <ToastContainer />
      <notificationsContext.Provider value={memoizedValue}>
        {props.children}
      </notificationsContext.Provider>
    </>
  );
}

export default NotificationProvider;
