const LEVELS = {
  INFO: 0,
  SUCCESS: 1,
  WARNING: 2,
  ALERT: 3,
  VERIFIED: 4,
};

export default LEVELS;

export const ICONS = {
  [LEVELS.INFO]: "c-info-s",
  [LEVELS.SUCCESS]: "check-full",
  [LEVELS.WARNING]: "t-warning-s",
  [LEVELS.ALERT]: "c-warning-s",
  [LEVELS.VERIFIED]: "verified",
};

// first member is icon color, last member is background-color
export const COLORS = {
  [LEVELS.INFO]: ["text-indigo-500", "bg-indigo-100"],
  [LEVELS.SUCCESS]: ["text-green-400", "bg-green-100"],
  [LEVELS.WARNING]: ["text-yellow-600", "bg-yellow-100"],
  [LEVELS.ALERT]: ["text-red-600", "bg-red-100"],
  [LEVELS.VERIFIED]: ["text-green-400", "bg-green-100"],
};
