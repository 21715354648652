import React from "react";
import Icon from "./Icon";

function getClasses(props) {
  const {
    small,
    full,
    big,
    problem,
    dark,
    primary,
    disabled,
    className,
  } = props;

  const variations = {
    default: `
      border-gray-300 
      text-gray-700 
      bg-white 
      hover:text-gray-700 
      hover:bg-gray-100
      
      focus:border-blue-300 
      
      active:text-gray-900 
      active:bg-gray-50
    `,
    primary: `
      text-white 
      bg-purple-500 
      
      hover:bg-purple-700 
      active:bg-purple-700 
      focus:border-purple-700 
    `,
    dark: `
      text-white 
      bg-gray-700 
      hover:bg-gray-600 
      
      focus:border-gray-800
      active:bg-purple-600 
    `,
    problem: `
      text-white
      bg-red-400
    `,
    disabled: `
      text-white
      bg-gray-600
      hover:bg-gray-600
      focus:border-gray-600
      active:bg-gray-600 

      cursor-not-allowed
    `,
  };

  const sizeVar = {
    default: `
      px-4
      py-2
    `,
    big: `
      px-7
      py-4
      text-lg
      font-bold
    `,
    small: `
      px-2
      py-1
    `,
  };

  let variation = "default";

  if (dark) variation = "dark";
  if (primary) variation = "primary";
  if (disabled) variation = "disabled";
  if (problem) variation = "problem";

  let size = "default";

  if (big) size = "big";
  if (small) size = "small";

  const style = variations[variation];
  const sizeStyle = sizeVar[size];

  const finalClassName = `
    flex
    inline-flex
    justify-center
    items-center

    border
    border-transparent
    text-sm
    leading-5
    font-medium
    rounded-md

    text-center

    focus:outline-none
    focus:shadow-outline-indigo

    transition duration-150
    ease-in-out

    tracking-wider

    ${style}
    ${sizeStyle}
    ${full && "w-full"}
    ${className}
  `;

  return finalClassName;
}
function Button(props) {
  const {
    as,

    icon,
    iconPosition,
    children,

    shadow,
    primary,

    full,
    small,
    big,

    className,

    ...rest
  } = props;

  const wrappedChildren = (
    <>
      {iconPosition === "right" && children}

      {icon && (
        <span className={`${primary ? "white" : "text-gray-500"}`}>
          <Icon iconPosition={iconPosition} icon={icon} />
        </span>
      )}

      {iconPosition === "left" && children}
    </>
  );

  const button = React.createElement(
    as,
    {
      className: getClasses(props),
      style: { WebkitAppearance: "none" },
      ...rest,
    },
    wrappedChildren
  );

  return shadow ? (
    <span className="shadow-sm rounded-md">{button}</span>
  ) : (
    button
  );
}

Button.defaultProps = {
  as: "button",
  type: "button",
  iconPosition: "left",
};

export default Button;
