import React from "react";

import PropTypes from "prop-types";

import Button from "components/Button";

function Modal(props) {
  const {
    title,
    subtitle,

    status,

    children,
    confirmText,
    confirmDisabled,

    cancelText = "Cancel",

    onConfirm,
    onCancel,

    // wether the cancel and confirm buttons are rendered
    cancel = true,
    confirm = true,
    ...rest
  } = props;

  const handleConfirm = React.useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  const handleCancel = React.useCallback(() => {
    if (typeof onCancel === "function") {
      onCancel();
    }
  }, [onCancel]);

  return (
    <div
      {...rest}
      className="fixed bottom-0 inset-x-0 px-4 py-6 sm:inset-0 z-50 overflow-auto"
    >
      <div className="fixed inset-0 transition-opacity" onClick={handleCancel}>
        <div className="absolute inset-0 bg-gray-500 opacity-75" />
      </div>
      <div className="m-auto bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
        <div>
          {status && (
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <svg
                className="h-6 w-6 text-green-600"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          )}

          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-bold mb-8 text-gray-900">
              {title}
            </h3>

            {subtitle && (
              <div className="mt-2">
                <div className="text-sm leading-5 text-gray-500">
                  {subtitle}
                </div>
              </div>
            )}
          </div>
        </div>

        {children}

        <div className="mt-8 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          {cancel && <Button onClick={handleCancel}>{cancelText}</Button>}
          {confirm && (
            <Button primary onClick={handleConfirm} disabled={confirmDisabled}>
              {confirmText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  confirmText: "Confirm",
  confirmDisabled: false,
};

Modal.propsType = {
  onConfirm: PropTypes.function,
  onCancel: PropTypes.function,

  confirmText: PropTypes.string,
  confirmDisabled: PropTypes.bool,

  title: PropTypes.string.isRequired,
  children: PropTypes.element,
};

export default Modal;
