import React from "react";
import { NavLink } from "react-router-dom";

import Icon from "components/Icon";

const getNavClass = (slim) => `
  mt-2 
  py-1
  px-2

  font-bold

  ${
    slim
      ? `
  `
      : `
    py-3
    px-6
  `
  }

  group
  flex
  items-center
  justify-between
  text-sm

  leading-5
  font-medium

  rounded-md
  transition
  ease-in-out
  duration-150
  relative

  capitalize

  border-2
  border-transparent

  focus:outline-none
  focus:border-purple-500

  hover:bg-purple-100
`;
const activeClassName = `
  text-white
  bg-purple-500
  hover:bg-purple-500
`;

export default function NavItem(props) {
  const { slim, to, tag, type, icon, label, children } = props;

  if (type === "section")
    return (
      <div className="mb-8">
        <div className={`title-small mb-4 ${slim ? "" : "px-6"}`}>{label}</div>
        <div>
          {children.map((item, index) => (
            <NavItem {...item} slim={slim} key={`${to}_${index}`} />
          ))}
        </div>
      </div>
    );

  return (
    <span className="text-gray-500">
      <NavLink
        to={to}
        key={to}
        className={getNavClass(slim)}
        activeClassName={activeClassName}
      >
        <div className="flex items-center capitalize">
          {icon && (
            <span className="mr-1">
              <Icon icon={icon} />
            </span>
          )}
          {label}
        </div>

        {tag ? (
          <div
            className="
              w-6 
              h-6 
              text-xs 
              flex 
              items-center 
              justify-center 
              bg-purple-500 
              text-center 
              text-white 
              rounded-md
            "
          >
            {tag}
          </div>
        ) : null}
      </NavLink>
    </span>
  );
}
