import React from "react";
import { logout } from "@farpoint/common/user-service";

export default function Logout() {
  React.useEffect(() => {
    logout();
    window.location.href = "/";
  }, []);

  return null;
}
