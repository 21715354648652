import React from "react";
import "styled-components/macro";

import logo from "images/logo.svg";

export function LoadingLogo() {
  return (
    <div className="flex flex-col items-center justify-center text-center">
      <div>
        <img
          src={logo}
          alt="Farpoint.io"
          className="h-12 [ animate fadeInUp fast ]"
        />
      </div>
      <div className="text-gray-700 text-xs [ animate fadeInUp ] ">
        Loading...
      </div>
    </div>
  );
}

function LoadingOverlay() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-500 flex items-center justify-center">
      <LoadingLogo />
    </div>
  );
}

export default LoadingOverlay;
