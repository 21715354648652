const { ApolloClient, createHttpLink } = require("@apollo/client");
const { setContext } = require("@apollo/client/link/context");
const { InMemoryCache } = require("@apollo/client/cache");

const possibleTypes = require("./fragment-types.json");

function createApolloClient(getUser, uri) {
  const httpLink = createHttpLink({
    uri,
  });

  // adds user token to graphql context
  const authLink = setContext((_, { headers }) => {
    const { token } = getUser();

    return {
      headers: {
        ...headers,
        token: `${token}`,
      },
    };
  });

  const cache = new InMemoryCache({
    possibleTypes,
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  });

  return client;
}

export default createApolloClient;
