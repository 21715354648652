import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

const flashContext = React.createContext();

export function useFlashMessage() {
  const navigate = useNavigate();

  const { showMessage } = useContext(flashContext);

  const navigateWithFlash = React.useCallback(
    (to, message) => {
      navigate(to);
      showMessage(message);
    },
    [showMessage, navigate]
  );

  const showFlashMessage = React.useCallback(
    (message) => {
      showMessage(message);
    },
    [showMessage]
  );

  return { navigateWithFlash, showFlashMessage };
}

export function FlashProvider(props) {
  const [messageVisible, setShowMessage] = React.useState(false);

  const messageRef = React.useRef({});

  const dismissTimer = React.useRef();

  const showMessage = React.useCallback(
    (message) => {
      messageRef.current = { message };
      setShowMessage(true);

      dismissTimer.current = window.setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    },
    [setShowMessage]
  );

  return (
    <flashContext.Provider value={{ showMessage }}>
      {props.children}
      {messageVisible && (
        <FlashMessage
          onClick={() => setShowMessage(false)}
          {...messageRef.current}
        />
      )}
    </flashContext.Provider>
  );
}

function FlashMessage(props) {
  const { onClick, message } = props;

  return (
    <div className="[ animated slideInUp faster ] fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-40">
      <div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-lg bg-purple-600 shadow-lg sm:p-3">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-purple-800">
                <svg
                  className="h-6 w-6 text-white"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                  />
                </svg>
              </span>
              <p className="ml-3 font-medium text-white truncate">{message}</p>
            </div>

            <div
              className="order-2 flex-shrink-0 sm:order-3 sm:ml-2"
              onClick={onClick}
            >
              <button
                type="button"
                className="-mr-1 flex p-2 rounded-md hover:bg-purple-500 focus:outline-none focus:bg-purple-500 transition ease-in-out duration-150"
                aria-label="Dismiss"
              >
                <svg
                  className="h-6 w-6 text-white"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlashMessage;
