import React from "react";
import "styled-components/macro";

function ErrorMessage(props) {
  return (
    <div
      role="alert"
      className={`mt-2 text-sm text-red-600 ${props.className || ""}`}
    >
      {typeof props.children === "string"
        ? props.children.replace("GraphQL error:", "")
        : props.children}
    </div>
  );
}

export default ErrorMessage;
