import React from "react";
import PropTypes from "prop-types";
import iconsList from "icons/icons-list.json";

function getIconClasses({ iconPosition, unspaced }) {
  if (unspaced) return ``;

  switch (iconPosition) {
    case "left":
      return `-ml-1 mr-2`;
    default:
      return `-mr-1 ml-2`;
  }
}

function Icon(props) {
  const { icon, onClick, className } = props;

  return (
    <span
      onClick={onClick}
      className={`farpoint-icon farpoint-icon-${icon} ${className} flex items-center ${getIconClasses(
        props
      )}`}
    />
  );
}

Icon.defaultProps = {
  unspaced: false,
  iconPosition: "left",
};

Icon.propTypes = {
  icon: PropTypes.oneOf(iconsList),
};

export default Icon;
