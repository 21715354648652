import "intersection-observer";

import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import apolloClient from "utility/apollo-client";
import PrivateRoute from "components/private-route";

import Logout from "pages/logout";
import PersistUserData from "utility/persist-user-data-provider";
import NotificationsProvider from "modules/notifications-provider";
import { PINProvider } from "modules/pin-modal";
import { FlashProvider } from "modules/flash-messages";

import ProductRoutes from "routes/products";
import QueryStringProvider from "components/query-string-provider";
import Layout from "layouts/main";

const SignInPage = React.lazy(() => import("pages/sign-in"));
const SignUpPage = React.lazy(() => import("pages/sign-up"));
const ActivateUser = React.lazy(() => import("pages/activate-user"));
const ActivateByInvitation = React.lazy(() =>
  import("pages/activate-by-invitation")
);

const RecoverPassword = React.lazy(() => import("pages/recover-password"));
const ResetPassword = React.lazy(() => import("pages/reset-password"));

const FavoritesListing = React.lazy(() => import("pages/favorites-listing"));

const OrderPage = React.lazy(() => import("pages/order"));

const Notifications = React.lazy(() => import("pages/dashboard/notifications"));
const MyProducts = React.lazy(() => import("pages/dashboard/my-products"));
const BuyOrders = React.lazy(() => import("pages/dashboard/buy-orders"));
const SellOrders = React.lazy(() => import("pages/dashboard/sell-orders"));

function PrivateApp() {
  // redirect if user is not logged in
  return (
    <FlashProvider>
      <NotificationsProvider>
        <PINProvider>
          <>
            <Layout>
              <Suspense fallback={null}>
                <Routes>
                  <Route path="/" element={<Navigate to="/products" />} />

                  <Route
                    path="/favorites"
                    element={<FavoritesListing favorites={true} />}
                  />

                  <Route
                    path="/dashboard/notifications"
                    element={<Notifications />}
                  />
                  <Route path="/dashboard/buy-orders" element={<BuyOrders />} />
                  <Route
                    path="/dashboard/sell-orders"
                    element={<SellOrders />}
                  />
                  <Route
                    path="/dashboard/my-products"
                    element={<MyProducts />}
                  />

                  <Route path="/orders/:id" element={<OrderPage />} />
                  <Route path="/products/*" element={<ProductRoutes />} />
                </Routes>
              </Suspense>
            </Layout>
          </>
        </PINProvider>
      </NotificationsProvider>
    </FlashProvider>
  );
}

function App() {
  return (
    <>
      <ApolloProvider client={apolloClient}>
        <Router>
          <QueryStringProvider>
            <React.Suspense fallback={null}>
              <Routes>
                <Route path="sign-in" element={<SignInPage />} />
                <Route path="sign-up/*" element={<SignUpPage />} />

                <Route
                  path="sign-up/activate"
                  exact
                  strict
                  element={<ActivateUser />}
                />
                <Route
                  path="sign-up/activate-by-invitation"
                  exact
                  strict
                  element={<ActivateByInvitation />}
                />

                <Route path="password/recover" element={<RecoverPassword />} />
                <Route path="password/reset" element={<ResetPassword />} />

                <Route path="logout" element={<Logout />} />

                <PrivateRoute
                  path="*"
                  element={
                    <PersistUserData>
                      <PrivateApp />
                    </PersistUserData>
                  }
                />
              </Routes>
            </React.Suspense>
          </QueryStringProvider>
        </Router>
      </ApolloProvider>
    </>
  );
}

export default App;
